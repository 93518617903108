import React, { useRef, useEffect, useState } from 'react'
import { navigate, graphql } from 'gatsby'
import {
  useLocation,
  getAuth,
  getOrderAfterPaymentData,
  OrderStatus,
  Loading,
  getStoredMarket,
  getCartStateByOrderId,
  useShoppingCart,
  getStoredDistributionCenter,
  useAuth,
} from '@ecommerce/shared'
import { v4 as uuidv4 } from 'uuid'
import AfterPayment from '../../templates/afterPayment'
import { PgPageProps } from '../../types/PgPages'
import withPageTransition from '../../components/withPageTransition'
import secrets from '../../config/secrets'
import { stringArrayToElementList, onRedirectBack, PageWrapper } from '../../components/Payment'
import LayoutStaticPage from '../../components/Layout/LayoutStaticPage'
import { sendRefundEvent, sendPageViewEvent } from '../../utils/events'
import DeviceFingerprintHelmet from '../../components/DeviceFingerprintHelmet'
import { useGiftCard } from '../../components/Checkout/components/PaymentMethods/GiftCard/useGiftCard'
import { useCoupon } from '../../components/Checkout/components/CouponCode/useCoupon'

const { COUNTRY } = secrets

const PaymentCancelled = (props: PgPageProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isOpenCart, setIsOpenCart] = useState(false)
  const [orderNumber, setOrderNumber] = useState<number>(0)

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()
  const { isBolivia } = useLocation()

  const isAuth = getAuth()
  const isBO = isBolivia()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Pago cancelado' })
  }, [])

  const {
    title,
    upperText,
    image,
    upperButtonTextUser,
    upperButtonTextGuest,
    bottomText,
  } = props.data.allContentfulPgPage.edges[0].node.template

  const currentMarket = getStoredMarket()
  const currentDistributionCenter = getStoredDistributionCenter()

  const refSessionId = useRef(uuidv4())

  const params = new URLSearchParams(props.location.search)
  const orderId = params.get('req_reference_number') || ''

  const { onRemoveGiftCard } = useGiftCard({ orderId })
  const { onRemoveCoupon } = useCoupon({ orderId })

  const goBack = () => onRedirectBack('/')

  const revertCouponOrGiftCard = async (couponCode: string | null, giftCardCode: string | null) => {
    if (couponCode) {
      await onRemoveCoupon()
    }
    if (giftCardCode) {
      await onRemoveGiftCard()
    }
  }

  const fetchDataOrderDataOrRetry = async () => {
    if (!orderId || !currentMarket) {
      goBack()
      return
    }
    try {
      const data = await getOrderAfterPaymentData(orderId, COUNTRY)
      if (!data) return goBack()
      sendRefundEvent(orderId, data, isBO)
      const { status, number, couponCode, giftCardCode } = data
      setOrderNumber(number)
      const canRetry = status === OrderStatus.PLACED
      if (!canRetry) {
        revertCouponOrGiftCard(couponCode, giftCardCode)
        return goBack()
      }
      revertCouponOrGiftCard(couponCode, giftCardCode)
      setIsLoading(false)
    } catch (error) {
      goBack()
    }
  }

  useEffect(() => {
    fetchDataOrderDataOrRetry()
  }, [])

  const { replaceState, state } = useShoppingCart()

  const retryPayment = async () => {
    if (currentMarket) {
      const {
        state: { byHash, globalQuantity, globalTotal, globalRawTotal },
      } = await getCartStateByOrderId(currentDistributionCenter?.commerceLayer.market.number, COUNTRY, orderId)
      replaceState({ ...state, byHash, globalQuantity, globalRawTotal, globalTotal })
      setIsOpenCart(true)
    }
  }

  return (
    <>
      {currentMarket && (
        <LayoutStaticPage title={title}>
          {currentMarket && (
            <DeviceFingerprintHelmet isBO={isBO} market={currentMarket} sessionId={refSessionId.current} />
          )}
          <PageWrapper>
            {isLoading ? (
              <Loading className="Loading" />
            ) : (
              <AfterPayment
                orderId={orderId}
                desktopTitle={title}
                upperText={stringArrayToElementList(upperText, orderNumber)}
                imgSrc={image.file.url}
                imgAlt="truck"
                upperButtonText={isAuth ? upperButtonTextUser : upperButtonTextGuest || upperButtonTextUser}
                upperButtonAction={retryPayment}
                lowerButtonText="Ir a Inicio"
                lowerButtonAction={() => navigate(`/`)}
                bottomText={bottomText ? stringArrayToElementList(bottomText, orderNumber) : undefined}
              />
            )}
          </PageWrapper>
        </LayoutStaticPage>
      )}
    </>
  )
}

export default withPageTransition(PaymentCancelled)

export const query = graphql`
  query PaymentCancelQuery($paymentCancelId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $paymentCancelId } }) {
      edges {
        node {
          slug
          title
          contentful_id
          template {
            ... on ContentfulTmAfterPayment {
              id
              image {
                id
                file {
                  url
                }
              }
              upperText
              lowerButtonText
              middleTextUser
              middleTextGuest
              title
              upperButtonTextGuest
              upperButtonTextUser
              bottomText
            }
          }
        }
      }
    }
  }
`
