import React from 'react'
import copy from 'copy-to-clipboard'
import { breakpoints, toCssPrefix, showToast } from '@ecommerce/shared'
import styled from 'styled-components'
import { Icon } from '../Icon/Icon'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  qrCodeRaw?: string
  qrCodeUrl?: string
  orderId: string
}

const { cssPrefix, toPrefix } = toCssPrefix('QRSection__')

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 20px 0 5px;

  .${cssPrefix} {
    &image {
      width: 120px;
      height: 120px;
      object-fit: contain;
      border: 1px solid ${({ theme }) => theme.colors.red};
      border-radius: 8px;
    }

    &share-section {
      margin-left: 20px;

      &-title {
        font-weight: bold;
        font-size: 14px;
      }

      &-links {
        display: flex;
        align-items: center;
      }

      &-link {
        width: 32px;
        height: 32px;
        margin: 0 4px;
        background: ${({ theme }) => theme.colors.red};
        border-radius: 50%;
        color: ${({ theme }) => theme.colors.white};
        border: none;
        padding: 0;
        cursor: pointer;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    margin: 0;
  }
`

const QRSection = ({ qrCodeRaw, qrCodeUrl, orderId, className = '', ...restProps }: Props) => {
  const getShareUrl = () => `${window.location.origin}/qr-code?id=${orderId}`
  const linkProps = (title: string) => ({
    title,
    className: toPrefix('share-section-link'),
    target: '_blank',
    rel: 'noopener noreferrer',
  })

  const onCopyLink = () => {
    copy(getShareUrl())
    showToast({ content: 'Enlace copiado', type: 'success' })
  }

  const downloadQRImage = () => {
    const anchor = document.createElement('a')

    anchor.href = `data:image/png;base64,${qrCodeRaw}`
    anchor.download = `codigo_qr_pedido_${qrCodeRaw}`
    anchor.click()
    anchor.remove()
  }

  if (!qrCodeRaw && !qrCodeUrl) return null

  return (
    <Wrapper {...restProps} className={`${cssPrefix} ${className}`}>
      <img
        className={`${toPrefix('image')} ${className}-image`}
        src={qrCodeRaw ? `data:image/png;base64,${qrCodeRaw}` : qrCodeUrl}
        alt="order-qr"
      />
      <div className={toPrefix('share-section')}>
        <p className={toPrefix('share-section-title')}>Compartir código QR</p>
        <div className={toPrefix('share-section-links')}>
          <a
            {...linkProps('Compartir en Whatsapp')}
            href={encodeURI(`https://api.whatsapp.com/send?text=${getShareUrl()}`)}
          >
            <Icon iconId="whatsapp" />
          </a>
          <button onClick={onCopyLink} title="Copiar enlace" className={toPrefix('share-section-link')} type="button">
            <Icon iconId="link" />
          </button>
          <a {...linkProps('Compartir por correo')} href={`mailto:?body=${encodeURI(getShareUrl())}`}>
            <Icon iconId="mail" />
          </a>
          <button onClick={downloadQRImage} type="button" title="Descargar" className={toPrefix('share-section-link')}>
            <Icon iconId="download" />
          </button>
        </div>
      </div>
    </Wrapper>
  )
}

export default QRSection
