/* eslint-disable react/no-danger */
import React from 'react'
import styled from 'styled-components'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  text: string
}

const { cssPrefix, toPrefix } = toCssPrefix('InfoBadge__')

const Wrapper = styled.div`
  width: 100%;
  background: rgba(240, 182, 63, 0.15);
  border: 1px solid ${({ theme }) => theme.colors.yellow};
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .${cssPrefix} {
    &icon {
      fill: ${({ theme }) => theme.colors.yellow};
      width: 45px;
    }

    &text {
      margin: 0;
      margin-left: 14px;
      font-size: 14px;
      color: #80621c;
      text-align: left;
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &icon {
        width: 25px;
        min-width: 25px;
      }
    }
  }
`

const InfoBadge = ({ text, className = '', ...restProps }: Props) => {
  return (
    <Wrapper {...restProps} className={`${cssPrefix} ${className}`}>
      <Icon className={toPrefix('icon')} iconId="warning_fill" />
      <p className={toPrefix('text')} dangerouslySetInnerHTML={{ __html: text }} />
    </Wrapper>
  )
}

export default InfoBadge
