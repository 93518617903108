import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { NavBar, Image, Anchor, breakpoints } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'

type ReactNode = React.ReactNode

export const CenterWrapper = styled.div`
  .img-logo {
    height: 45px;
    object-fit: contain;
  }
`

export const center = (
  <CenterWrapper>
    <Image
      src="https://images.ctfassets.net/16npdkkoi5mj/4EAocVvRjUs3wHYN7PiSDB/f89865bb82569f8a649d1c07fbfbfbd5/CCO_-_BLANCO_-_SVG.svg"
      alt="logo"
      className="img-logo"
    />
  </CenterWrapper>
)

export const Left = ({ slug }: { slug?: string }) => (
  <Anchor onClick={() => navigate(`/${slug}`)}>
    <Icon iconId="arrow_left" color="white" size="24" />
  </Anchor>
)

export const customNavBar = (slug?: string) => () => <NavBar left={<Left slug={slug} />} center={center} />

export const stringArrayToElementList = (array: string[] = [], orderId: number) =>
  array?.map((text) => {
    if (text === '$orderNumber') return <span key={text} style={{ fontWeight: 'bold' }}>{` N° ${orderId}`}</span>
    if (text === '<br/>') return <br />
    return <span key={text}>{text}</span>
  })

export const onRedirectBack = (slug: string) => navigate(slug)

export const PageWrapper = styled.div`
  .PageContainer {
    min-height: calc(100vh - 230px);
    position: relative;
    padding-bottom: 55px;

    .Loading {
      position: absolute;
      width: 84px;
      height: 84px;
      top: calc(50% - 70px);
    }

    @media screen and (${breakpoints.desktop.min}) {
      min-height: calc(100vh - 290px);
    }
  }
`
