import React from 'react'
import { Market } from '@ecommerce/shared'
import { Helmet } from 'react-helmet'
import secrets from '../../config/secrets'

interface DeviceFingerprintHelmetParams {
  market: Market
  sessionId: string
  isBO: boolean
}

/* FIXME: Reference Cybersource in the name */
const DeviceFingerprintHelmet = ({ market, sessionId, isBO }: DeviceFingerprintHelmetParams) => {
  if (!isBO) return null

  const fingerprintUrl = secrets.DEVICE_FINGERPRINT_JS_URL
  const orgId = secrets.DEVICE_FINGERPRINT_ORG_ID
  const commerceMid = market.metadata?.commerceMid || ''
  const deviceFingerprintUrl = `${fingerprintUrl}?org_id=${orgId}&session_id=${commerceMid}${sessionId}`

  return (
    <Helmet>
      <script src={deviceFingerprintUrl} />
    </Helmet>
  )
}

export default DeviceFingerprintHelmet
