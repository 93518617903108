import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { Image, breakpoints, Button, toCssPrefix } from '@ecommerce/shared'
import withPageTransition from '../components/withPageTransition'
import InfoBadge from '../components/InfoBadge'
import QRSection from '../components/QRSection'

const { cssPrefix, toPrefix } = toCssPrefix('PaymentSuccess__')

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  letter-spacing: -0.25px;
  text-align: center;
  max-width: 320px;
  padding: 70px 0;

  .${cssPrefix} {
    &image {
      height: 142px;
      width: 142px;
      margin-top: 19px;
      margin-bottom: 24px;
    }

    &desktop-title {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.11px;
      margin-bottom: 27px;
      display: none;
      @media (${breakpoints.desktop.min}) {
        display: inline;
      }
    }

    &middle-text {
      margin-bottom: 30px;
    }

    &bottom-text {
      margin-top: 32px;
    }

    &button {
      width: 250px;
      height: 40px;
      border-radius: ${(props) => props.theme.borderRadius};
      font-size: 17px;
      font-weight: bold;
      cursor: pointer;

      &.lower {
        margin-top: 15px;
      }
    }

    &qr-info {
      &-section {
        margin: 30px 0 40px;

        &-image {
          width: 130px;
          height: 130px;
        }
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &qr-info {
        &-section {
          &-image {
            width: 155px;
            height: 155px;
          }
        }
      }
    }
  }
`

interface AfterPaymentProps {
  orderId: string
  desktopTitle: string
  upperText: ReactNode
  imgSrc: string
  imgAlt: string
  middleText?: ReactNode
  upperButtonText: string
  upperButtonAction?: (e?: React.MouseEvent) => void
  lowerButtonText?: string
  lowerButtonAction?: (e?: React.MouseEvent) => void
  bottomText?: ReactNode
  infoText?: string
  QRRaw?: string
  QRUrl?: string
  hideUpperButton?: boolean
}

const AfterPayment = ({
  desktopTitle,
  upperText,
  imgSrc,
  imgAlt,
  middleText,
  lowerButtonText,
  upperButtonText,
  upperButtonAction,
  lowerButtonAction,
  bottomText,
  infoText,
  QRRaw,
  QRUrl,
  orderId,
  hideUpperButton,
}: AfterPaymentProps) => {
  const [loadingPayment, setLoadingPayment] = useState(false)

  const onUpperButtonClick = async (e: React.MouseEvent<Element, MouseEvent> | undefined) => {
    setLoadingPayment(true)
    if (upperButtonAction) await upperButtonAction(e)
    setLoadingPayment(false)
  }

  const HiddenButtonPlaceholder = styled.div`
    height: 40px;
  `

  return (
    <Wrapper>
      <span className={toPrefix('desktop-title')}>{desktopTitle}</span>
      <span className={toPrefix('upper-text')}>{upperText}</span>
      <Image preload={false} className={toPrefix('image')} src={imgSrc} alt={imgAlt} />
      {middleText && <span className={toPrefix('middle-text')}>{middleText}</span>}
      <div className={toPrefix('qr-info')}>
        {infoText && <InfoBadge text={infoText} />}
        {QRRaw || QRUrl ? (
          <QRSection className={toPrefix('qr-info-section')} orderId={orderId} qrCodeRaw={QRRaw} qrCodeUrl={QRUrl} />
        ) : null}
      </div>
      {!hideUpperButton && (
        <Button className={toPrefix('button')} type="button" isLoading={loadingPayment} onClick={onUpperButtonClick}>
          <span>{upperButtonText}</span>
        </Button>
      )}
      <Button
        className={toPrefix('button lower')}
        btnType={hideUpperButton ? 'primary' : 'secondary'}
        type="button"
        onClick={(e) => (lowerButtonAction ? lowerButtonAction(e) : '')}
      >
        <span>{lowerButtonText || 'Ir a Inicio'}</span>
      </Button>
      {bottomText && <span className="PaymentSuccess__bottom-text">{bottomText}</span>}
    </Wrapper>
  )
}

export default withPageTransition(AfterPayment)
