import { getStoredDistributionCenter, getStoredMarket, useShoppingCart } from '@ecommerce/shared'
import { useEffect, useState } from 'react'
import secrets from '../../../../../config/secrets'
import { useCheckout } from '../../../checkout.context'

type ErrorResponse = {
  message: string
  statusCode?: number
}

const GENERIC_ERROR_MESSAGE = 'Ha habido un error, por favor intenta de nuevo.'
const INVALID_COUPON_ERROR = 'El cupón que ingresaste no es válido.'

interface UseGiftCardInputProps {
  orderId: string
}

interface UseCouponInputReturn {
  errorState: { hasError: boolean; message: string }
  setGiftCard: (giftCard: string) => void
  isSuccess: boolean
  isLoading: boolean
  onApplyGiftCard: () => void
  onRemoveGiftCard: () => void
  giftCard: string
}

export const useGiftCard = ({ orderId }: UseGiftCardInputProps): UseCouponInputReturn => {
  const { COUNTRY } = secrets

  const { state } = useShoppingCart()
  const { setIsLoadingGiftCard, giftCard: appliedGiftCard, setGiftCardDetail } = useCheckout()

  const [giftCard, setGiftCard] = useState(appliedGiftCard ?? '')

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(!!appliedGiftCard)
  const [errorState, setErrorState] = useState({
    hasError: false,
    message: '',
  })

  const currentMarket = getStoredMarket()
  const distributionCenter = getStoredDistributionCenter()

  const { applyGiftCard, removeGiftCard } = useShoppingCart()

  useEffect(() => {
    setGiftCardDetail(state.discountDetails?.find((discount) => discount.sku === 'giftcard'))
  }, [appliedGiftCard])

  useEffect(() => {
    if (!appliedGiftCard && !errorState?.hasError) {
      setGiftCard(appliedGiftCard ?? '')
      setIsSuccess(!!appliedGiftCard)
    }
  }, [appliedGiftCard])

  const setError = (error: ErrorResponse) => {
    let errorData = { message: GENERIC_ERROR_MESSAGE } as ErrorResponse

    if (error.statusCode === 400) {
      const { message, statusCode } = error

      errorData = { ...errorData, message: message ?? GENERIC_ERROR_MESSAGE, statusCode }

      if (!error.statusCode) {
        errorData.message = GENERIC_ERROR_MESSAGE
      }
    }

    setErrorState({
      hasError: true,
      message: errorData.message,
    })
  }

  const onApplyGiftCard = async () => {
    try {
      if (!currentMarket) return

      setErrorState({ hasError: false, message: '' })
      setIsLoading(true)
      setIsLoadingGiftCard(true)

      await applyGiftCard({
        giftCard,
        orderId,
        country: COUNTRY,
        currentMarket,
      })

      setGiftCard(giftCard)

      setIsSuccess(true)
    } catch (error) {
      setError(error.response.data)
    } finally {
      setIsLoading(false)
      setIsLoadingGiftCard(false)
    }
  }

  const onRemoveGiftCard = async () => {
    try {
      if (!currentMarket) return
      setErrorState({ hasError: false, message: '' })
      setIsLoading(true)
      setIsLoadingGiftCard(true)

      await removeGiftCard({
        code: giftCard,
        orderId,
        country: COUNTRY,
        currentMarket,
        distributionCenterSlug: distributionCenter?.slug ?? '',
      })
      setIsSuccess(false)
      setGiftCard('')
    } catch (error) {
      setError(error.response.data)
    } finally {
      setIsLoading(false)
      setIsLoadingGiftCard(false)
    }
  }

  return {
    errorState,
    setGiftCard,
    isSuccess,
    isLoading,
    onApplyGiftCard,
    onRemoveGiftCard,
    giftCard,
  }
}
